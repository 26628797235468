<template>
  <section>
    <section class="relative py-20">
      <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style="height:80px;transform:translateZ(0)">
        <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon></svg>
      </div>
      <div class="container w-full mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-1/2 mx-auto px-4">
            <img alt="..." class="my-20 max-w-full rounded-lg shadow-lg" src="@/assets/images/exterior.jpg">
          </div>
          <div class="w-full md:w-2/3 lg:w-1/3 mx-auto px-4">
            <div class="md:pr-0 md:text-left">
              <!-- <div class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 rounded-full bg-transparent">
                <i class="fa fa-rocket text-xl"></i>
              </div> -->
              <!-- <h3 class="text-3xl font-semibold">A growing company</h3> -->
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                SAT restoration and cleaning services are committed to provide excellent service in time of need. Our response team is available 24/7 to provide mitigation and make safe works in timely manner to minimize damage to your property and contents.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Excellent service</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Provide mitigation</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Safe works in timely manner</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container w-full mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-2/3 lg:w-1/3 mx-auto px-4">
            <div class="md:pr-0 md:text-left">
              <!-- <div class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 rounded-full bg-transparent">
                <i class="fa fa-rocket text-xl"></i>
              </div> -->
              <!-- <h3 class="text-3xl font-semibold">A growing company</h3> -->
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                When it comes to any damage to your property such as water, Fire, Mold and Bio Hazard our team is
                equipped with expertise backed by 12-year experience in the restoration industry &amp; IICRC
                qualification to restore and mitigate damages in timely manner and provide safe and sanitary conditions.
              </p>   
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Expertise</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Qualified</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Peace of mind</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full md:w-1/2 mx-auto px-4">
            <img alt="..." class="my-20 max-w-full rounded-lg shadow-lg" src="@/assets/images/greenview.jpg">
          </div>
        </div>
      </div>
      <div class="container w-full mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-1/2 mx-auto px-4">
            <img alt="..." class="my-20 max-w-full rounded-lg shadow-lg" src="@/assets/images/interior.jpg">
          </div>
          <div class="w-full md:w-2/3 lg:w-1/3 mx-auto px-4">
            <div class="md:pr-0 md:text-left">
              <!-- <div class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 rounded-full bg-transparent">
                <i class="fa fa-rocket text-xl"></i>
              </div> -->
              <!-- <h3 class="text-3xl font-semibold">A growing company</h3> -->
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
Our team is expert in insurance industry and can assist while you make your insurance claim. We can
provide detail report outlining damage particulars and guide you in right direction from beginning.</p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Expert</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Insurance claim</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i class="far fa-check"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Guidance</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-1/2 px-4">
            <h2 class="text-4xl font-semibold">Here are our heroes</h2>
            <p class="text-lg leading-relaxed m-4 text-gray-600">According to the National Oceanic and Atmospheric Administration, Ted, Scambos, NSIDClead scentist, puts the potentially record maximum.</p>
          </div>
        </div>
        <div class="md:flex flex-wrap md:flex-no-wrap">
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img alt="..." src="https://i.pinimg.com/originals/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg" class="shadow-lg rounded-full max-w-full mx-auto" style="max-width:120px">
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Ryan Tompson</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">Web Developer</p>
                <div class="mt-6">
                  <button class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-twitter"></i>
                  </button>
                  <button class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-facebook-square"></i>
                  </button>
                  <button class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-dribbble"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img alt="..." src="https://i.pinimg.com/originals/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg" class="shadow-lg rounded-full max-w-full mx-auto" style="max-width:120px">
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Romina Hadid</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">Marketing Specialist</p>
                <div class="mt-6">
                  <button class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-google"></i>
                  </button>
                  <button class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-facebook-square"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img alt="..." src="https://i.pinimg.com/originals/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg" class="shadow-lg rounded-full max-w-full mx-auto" style="max-width:120px">
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Alexa Smith</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">UI/UX Designer</p>
                <div class="mt-6">
                  <button class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-google"></i>
                  </button>
                  <button class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-twitter"></i>
                  </button>
                  <button class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-instagram"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img alt="..." src="https://i.pinimg.com/originals/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg" class="shadow-lg rounded-full max-w-full mx-auto" style="max-width:120px">
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Jenna Kardi</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">Founder and CEO</p>
                <div class="mt-6">
                  <button class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-dribbble"></i>
                  </button>
                  <button class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-google"></i>
                  </button>
                  <button class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-twitter"></i>
                  </button>
                  <button class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-instagram"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </section>
</template>

<script>
import {
  // SearchIcon,
} from "vue-feather-icons";
export default {
  name: "aboutus",
  components: {
    // SearchIcon,
  },
  data() {
    return {};
  },
  props: {},
  watch: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
